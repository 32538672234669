"use client";
import { MainSection } from "@/layouts/base-layout/navigation/main-section";
import {
  NavSkeleton,
  NavSkeletonProps,
} from "@/layouts/base-layout/navigation/nav-skeleton";

import { useBaseLayout } from "@/layouts/base-layout/shared";
import { Skeleton } from "@llamaindex/component/ui/skeleton";
import { Suspense } from "react";

export const Navigation = (props: Pick<NavSkeletonProps, "layoutRef">) => {
  const { serverNavigationItems } = useBaseLayout();
  return (
    <NavSkeleton layoutRef={props.layoutRef}>
      <Suspense fallback={<Skeleton className="h-[125px] w-full rounded-xl" />}>
        <MainSection />
      </Suspense>
      <div className="grow" />
      {serverNavigationItems}
      <div className="mb-8" />
    </NavSkeleton>
  );
};
