"use client";
import { Button } from "@llamaindex/component/ui/button";
import { useRouter } from "next/navigation";
import { useCallback } from "react";

interface ManageOrganizationButtonProps {
  className?: string;
  organizationId: string;
}

export function ManageOrganizationButton({
  organizationId,
}: ManageOrganizationButtonProps) {
  const { push } = useRouter();

  const handleClick = useCallback(() => {
    push(`/organization/${organizationId}/settings/organization`);
  }, [organizationId, push]);

  return (
    <Button
      variant="outline"
      className="flex w-full gap-2 whitespace-nowrap text-sm"
      onClick={handleClick}
    >
      Manage Organization
    </Button>
  );
}
