"use client";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/store";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@llamaindex/component/ui/resizable";
import React, { ReactNode, useCallback, useRef } from "react";
import { ImperativePanelGroupHandle } from "react-resizable-panels";
import { Navigation } from "./navigation";

export type BaseLayoutClientProps = {
  className?: string;
  children: ReactNode;
  navigation?: ReactNode;
};

export const BaseLayoutClient: React.FC<BaseLayoutClientProps> = (props) => {
  const panelSizes = useAppStore((state) => state.panelSizes);
  const setPanelSizes = useAppStore((state) => state.setPanelSizes);
  const ref = useRef<ImperativePanelGroupHandle>(null);
  return (
    <ResizablePanelGroup
      ref={ref}
      direction="horizontal"
      className="relative flex h-full flex-row bg-gray-50"
      onLayout={useCallback(
        (sizes: number[]) => {
          setPanelSizes(sizes);
        },
        [setPanelSizes],
      )}
    >
      <Navigation layoutRef={ref} />
      <ResizableHandle className="hidden" />
      <ResizablePanel defaultSize={panelSizes[1]} minSize={30}>
        <div
          className={cn(
            "flex h-full grow flex-col overflow-auto",
            props.className,
          )}
        >
          {props.children}
        </div>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};
