"use client";
import { CloudPermissions, usePermission } from "@/hooks/permissions";
import { useNewProject } from "@/hooks/project";
import { ERROR_TOAST_DURATION_MS } from "@/lib/constants";
import { Button } from "@llamaindex/component/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@llamaindex/component/ui/dialog";
import { Input } from "@llamaindex/component/ui/input";
import { toast } from "@llamaindex/component/ui/use-toast";
import { FolderPlus } from "lucide-react";
import { useRouter } from "next/navigation";
import { ReactNode, useActionState } from "react";

interface CreateProjectButtonProps {
  organizationId?: string;
  className?: string;
}

export function CreateProjectButton({
  organizationId,
  className,
}: CreateProjectButtonProps) {
  const { mutateAsync } = useNewProject();
  const { push } = useRouter();
  const hasPermission = usePermission(CloudPermissions.FULL_WRITE);
  const [button, action] = useActionState<ReactNode, FormData>(
    async (state, payload) => {
      const projectName = payload.get("project-name");
      if (typeof projectName === "string" && projectName) {
        const project = await mutateAsync({
          organizationId,
          name: projectName,
        }).catch((error) => {
          toast.error(
            "Error when creating project: " +
              (error.body?.detail ?? error.message),
            { duration: ERROR_TOAST_DURATION_MS },
          );
          return null;
        });
        if (project) {
          toast.success("Project created");
          push(`/project/${project.id}/pipeline/`);
        }
      }
      return state;
    },
    <Button
      variant="outline"
      className="flex w-full gap-2 whitespace-nowrap"
      disabled={!hasPermission}
    >
      <FolderPlus className="size-4" />
      Create Project
    </Button>,
  );
  return (
    <Dialog>
      <DialogTrigger asChild>{button}</DialogTrigger>
      <DialogContent>
        <DialogHeader>New Project</DialogHeader>
        <form action={action}>
          <Input name="project-name" placeholder="Project Name" />
          <DialogFooter className="mt-4">
            <DialogClose asChild>
              <Button type="submit">Create</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
