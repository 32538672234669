"use client";
import { deleteSession } from "@/actions/session";
import { CreateOrganizationButton } from "@/components/section/create-organization-button";
import { CreateProjectButton } from "@/components/section/create-project-button";
import { ManageOrganizationButton } from "@/components/section/manage-organization-button";
import { useAuth } from "@/hooks/auth";
import { useOrganizations } from "@/hooks/organization";
import { useProjects } from "@/hooks/project";
import { googleAuth, runtimeFlags } from "@/lib/runtime";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/store";
import { OpenAPI } from "@llamaindex/cloud";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@llamaindex/component/ui/avatar";
import { Button } from "@llamaindex/component/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@llamaindex/component/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@llamaindex/component/ui/popover";
import { toast } from "@llamaindex/component/ui/use-toast";
import { Check, ChevronsUpDown } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";

const ProjectMenu = () => {
  const organizationId = useAppStore((store) => store.organizationId);
  const setOrganizationId = useAppStore((store) => store.setOrganizationId);
  const setProjectId = useAppStore((store) => store.setProjectId);
  const currentProjectId = useAppStore((store) => store.projectId);

  const [orgOpen, setOrgOpen] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);

  const { data: projects } = useProjects();
  const { data: organizations } = useOrganizations();
  const pathname = usePathname();
  const { push, replace } = useRouter();

  const handleOrganizationChange = useCallback(
    async (newOrgId: string) => {
      if (newOrgId !== organizationId) {
        setOrganizationId(newOrgId);

        const newOrgProjects = projects.filter(
          (project) => project.organization_id === newOrgId,
        );
        const defaultProject =
          newOrgProjects.find((p) => p.is_default) || newOrgProjects[0];

        if (defaultProject) {
          setProjectId(defaultProject.id);
          replace(`/project/${defaultProject.id}/pipeline/`);
        } else {
          setProjectId(null);
          push(`/organization/${newOrgId}/settings`);
        }
      }
      setOrgOpen(false);
    },
    [organizationId, setOrganizationId, projects, setProjectId, replace, push],
  );

  const handleProjectChange = useCallback(
    (newProjectId: string) => {
      const project = projects.find((p) => p.id === newProjectId);
      if (project) {
        if (project.organization_id !== organizationId) {
          setOrganizationId(project.organization_id);
        }
        setProjectId(newProjectId);
        replace(`/project/${newProjectId}/pipeline/`);
      }
      setProjectOpen(false);
    },
    [projects, organizationId, setOrganizationId, setProjectId, replace],
  );

  useEffect(() => {
    const pathProjectId = pathname.match(/\/project\/([^/]+)/)?.[1];

    if (pathProjectId && pathProjectId !== currentProjectId) {
      const project = projects.find((p) => p.id === pathProjectId);
      if (project) {
        if (project.organization_id !== organizationId) {
          // If the project belongs to a different organization, change the organization
          setOrganizationId(project.organization_id);
        }
        setProjectId(project.id);
      }
    } else if (pathProjectId && currentProjectId && !organizationId) {
      const project = projects.find((p) => p.id === currentProjectId);
      if (project) {
        setOrganizationId(project.organization_id);
        replace(`/project/${project.id}/pipeline/`);
      }
    } else if (!pathProjectId && !currentProjectId && !organizationId) {
      const defaultOrg = organizations[0];

      if (defaultOrg) {
        setOrganizationId(defaultOrg.id);
      }

      const defaultProject =
        projects.find((p) => p.organization_id === defaultOrg?.id) ||
        projects[0];

      if (defaultProject) {
        setProjectId(defaultProject.id);
        replace(`/project/${defaultProject.id}/pipeline/`);
      }
    }
  }, [
    pathname,
    projects,
    organizations,
    replace,
    organizationId,
    currentProjectId,
    setOrganizationId,
    setProjectId,
  ]);

  const currentOrganization = useMemo(() => {
    return organizations.find((o) => o.id === organizationId);
  }, [organizations, organizationId]);

  const currentOrgProjects = useMemo(() => {
    return projects.filter((p) => p.organization_id === organizationId);
  }, [projects, organizationId]);

  return (
    <div className="flex items-center space-x-2">
      <Popover open={orgOpen} onOpenChange={setOrgOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={orgOpen}
            className="w-[200px] justify-between"
          >
            {currentOrganization
              ? currentOrganization.name
              : "Select organization..."}
            <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search organization..." />
            <CommandEmpty>No organization found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {organizations.map((organization) => (
                  <CommandItem
                    key={organization.id}
                    value={organization.name}
                    onSelect={() => handleOrganizationChange(organization.id)}
                  >
                    <Check
                      className={cn(
                        "mr-2 size-4",
                        organizationId === organization.id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {organization.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            <CommandItem>
              <CreateOrganizationButton />
            </CommandItem>
            {currentOrganization && (
              <CommandItem>
                <ManageOrganizationButton
                  organizationId={currentOrganization.id}
                />
              </CommandItem>
            )}
          </Command>
        </PopoverContent>
      </Popover>
      <div>/</div>
      <Popover open={projectOpen} onOpenChange={setProjectOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={projectOpen}
            className="w-[200px] justify-between"
          >
            {currentProjectId
              ? currentOrgProjects.find((p) => p.id === currentProjectId)
                  ?.name || "Select project..."
              : "Select project..."}
            <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search project..." />
            <CommandEmpty>No project found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {currentOrgProjects.map((project) => (
                  <CommandItem
                    key={project.id}
                    value={project.name}
                    onSelect={() => handleProjectChange(project.id)}
                  >
                    <Check
                      className={cn(
                        "mr-2 size-4",
                        currentProjectId === project.id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {project.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            <CommandItem>
              <CreateProjectButton organizationId={currentOrganization?.id} />
            </CommandItem>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const ProjectHeader = () => {
  const { user } = useAuth();
  const { replace } = useRouter();

  const handleSignOut = useCallback(async () => {
    try {
      switch (runtimeFlags.authMode) {
        case "google": {
          if (!googleAuth) return;
          await deleteSession();
          await googleAuth.signOut();
          break;
        }
        case "self-host-byoc": {
          await fetch(`${OpenAPI.BASE}/api/v1/auth/logout`);
          break;
        }
        default:
          await fetch(`${OpenAPI.BASE}/api/v1/auth/logout`);
      }
    } catch (error) {
      toast.error("Error signing out");
      console.error("Error signing out", error);
    }
    localStorage.clear();
    replace("/login");
  }, [replace]);

  const projectId = useAppStore((store) => store.projectId);

  return (
    <div className="sticky top-0 flex h-[60px] max-h-[60px] min-h-[60px] w-full flex-row items-center border-b border-b-slate-200 bg-background px-6">
      <Link href={projectId ? `/project/${projectId}/pipeline` : "/"}>
        <Image
          src="/assets/logo.svg"
          alt="LlamaIndex"
          className="block cursor-pointer"
          style={{
            height: "auto",
          }}
          height={36}
          width={120}
        />
      </Link>
      <div className="ml-8 grow">
        <ProjectMenu />
      </div>
      {user ? (
        <div className="flex flex-row gap-1 text-end">
          <div className="flex h-full items-center text-sm">
            <div className="mr-3 flex flex-col items-end">
              {/* <a href="#" onClick={handleSignOut}> */}
              <div className="text-xs">
                {user.email}
                {/* <ChevronDown className="inline h-3 w-3" /> */}
              </div>
              <div
                className="cursor-pointer text-xs text-slate-500 hover:text-slate-700"
                onClick={handleSignOut}
              >
                Sign Out
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Avatar className="size-8 self-center">
              <AvatarImage
                src={user.photoURL || ""}
                alt={user.displayName || user.email || ""}
              />
              <AvatarFallback>
                {(user.displayName || user.email || "")
                  .slice(0, 2)
                  .toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </div>
        </div>
      ) : // there is not this UI if user is not logged in
      null}
    </div>
  );
};
