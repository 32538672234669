"use client";
import { ProjectHeader } from "@/layouts/base-layout/header/project-header";
import { useBaseLayout } from "@/layouts/base-layout/shared";

export const Header = () => {
  const { serverHeaderItems } = useBaseLayout();
  return (
    <>
      {serverHeaderItems}
      <ProjectHeader />
    </>
  );
};
